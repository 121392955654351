import { useContext, useEffect, useState } from "react";
import { ColorModeContext } from "../theme";

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";

export const Topbar = ({ toggleCollapse, setLogged, setMenuClicked }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    let data = JSON.parse(window.localStorage.getItem("user"));
    setUser(data?.name);
    setEmail(data?.email);
  }, []);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        boxShadow="0 0.313rem 0.75rem rgb(7 9 25 / 5%), 0 0.126rem 0.15rem rgb(7 9 25 / 3%)"
        width="100%"
        height="70px"
      >
        <IconButton
          onClick={() => {
            toggleCollapse();
            setMenuClicked((prevState) => !prevState);
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "light" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
          {!isSmallScreen && (
            <Box
              mx={2}
              display="flex"
              flexDirection="column"
              minWidth="fit-content"
            >
              <Typography variant="h5" fontWeight="bold">
                {user}
              </Typography>
              <Typography>{email}</Typography>
            </Box>
          )}
          <IconButton onClick={() => setOpenPopup(true)}>
            <LogoutOutlinedIcon />
          </IconButton>
        </Box>
      </Box>

      <Popup
        title={"LOGOUT"}
        openPopup={openPopup}
        onClose={() => setOpenPopup(false)}
        buttons={[
          {
            title: "Continue",
            action: () => {
              window.localStorage.removeItem("user");
              window.localStorage.removeItem("token");
              setLogged(false);
              navigate("/login");
            },
          },
          {
            title: "Cancel",
            variant: "outlined",
            action: () => setOpenPopup(false),
          },
        ]}
      >
        Continue to logout?
      </Popup>
    </div>
  );
};
