import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// mui theme settings
export const themeSettings = (mode) => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1536,
        application: 1200,
      },
    },
    palette: {
      mode: mode,
      sidebar: {
        background: "#283641",
        text: "white",
      },
      primary: {
        main: "#539165",
      },
      secondary: {
        main: "#f7c04a"
      },
      ...(mode === "dark"
        ? {
          background: {
            default: "#12171a",
          },
          spinner: {
            background: "none",
            color: "#539165",
            blur: "blur(5px)",
          },
          footer: {
            background: "#283641",
          },
          table: {
            background: "#283641",
          },
          popup: {
            blur: "blur(2px)",
            backdropColor: "none",
            background: "#283641",
          },
          formContainer: {
            background: "#283641",
          },
          header: "#e0e0e0",
        }
        : {
          background: {
            default: "#fcfcfc",
          },
          spinner: {
            background: "none",
            color: "#539165",
            blur: "blur(5px)",
          },
          footer: {
            background: "#fcfcfc",
          },
          table: {
            background: "#fcfcfc",
          },
          popup: {
            blur: "blur(2px)",
            backdropColor: "none",
            background: "#fcfcfc",
          },
          formContainer: {
            background: "#fcfcfc",
          },
          header: "#141414",
        }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },

    components: {
      MuiIconButton: {
        defaultProps: {
          color: "primary",
          disableFocusRipple: true,
          disableRipple: true,
        },
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
