import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ logged, setLogged }) => {
  let location = useLocation();
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const user = window.localStorage.getItem("user");
    if (!token || !user) {
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("token");
      setLogged(false);
    } else {
      setLogged(true);
    }
  }, [location, setLogged]);
  if (logged === true) {
    return <Outlet />;
  } else if (logged === false) {
    return <Navigate to="/login" />;
  }
};
