import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const Popup = ({
  openPopup,
  onClose,
  title,
  children,
  noFooter = false,
  buttons,
}) => {
  const theme = useTheme();

  let isComponent = React.isValidElement(children);

  const style = {
    py: "2rem",
  };

  return (
    <Dialog
      open={openPopup}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: theme.palette.popup.backdropColor,
          backdropFilter: theme.palette.popup.blur,
        },
        "& .MuiPaper-root": {
          overflow: "hidden",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.popup.background,
          boxShadow: "none",
          minHeight: 200,
        },
      }}
    >
      {title && (
        <DialogTitle>
          <Typography
            component={"span"}
            variant="h3"
            fontWeight="bold"
            color="secondary.dark"
          >
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent
        dividers
        sx={{
          minWidth: "400px",
          width: "auto",
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        {isComponent ? (
          { ...children }
        ) : (
          <Typography variant="h4">{children}</Typography>
        )}
      </DialogContent>

      {!noFooter && (
        <DialogActions sx={{ px: 2 }}>
          {buttons &&
            buttons.map((item) => {
              return (
                item.title && (
                  <Button
                    key={uuidv4()}
                    size="large"
                    variant={item.variant ? item.variant : "contained"}
                    onClick={item.action}
                  >
                    {item.title}
                  </Button>
                )
              );
            })}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Popup;
