import { useTheme } from "@emotion/react";
import { Backdrop } from "@mui/material";
import React from "react";
import { ScaleLoader } from "react-spinners";

export const Spinner = () => {
  const theme = useTheme();
  return (
    <Backdrop
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backdropFilter: theme.palette.spinner.blur,
        background: theme.palette.spinner.background,
      }}
      open={true}
    >
      <ScaleLoader loading={true} color={theme.palette.spinner.color} />
    </Backdrop>
  );
};
