import {
  AccountBalanceOutlined,
  AddCardTwoTone,
  BadgeTwoTone,
  CardGiftcardTwoTone,
  CurrencyRupeeTwoTone,
  DescriptionTwoTone,
  HomeTwoTone,
  HomeWorkTwoTone,
  ImageTwoTone,
  InfoTwoTone,
  InsertDriveFileTwoTone,
  NotificationsTwoTone,
  PermIdentityTwoTone,
  PhonelinkSetupTwoTone,
  SettingsSuggestTwoTone,
  VideoSettingsTwoTone,
  WorkOutlineTwoTone,
} from "@mui/icons-material";

export const sidebarContents = [
  {
    header: "Dashboard",
  },
  {
    title: "Dashboard",
    to: "/",
    icon: <HomeTwoTone />,
  },
  {
    title: "Customer",
    to: "/customer/view",
    icon: <PermIdentityTwoTone />,
  },
  {
    title: "Projects",
    to: "/project/view",
    icon: <WorkOutlineTwoTone />,
  },
  {
    title: "Applications",
    to: "/application/view",
    icon: <InsertDriveFileTwoTone />,
  },
  {
    title: "Lucky Draw",
    to: "/luckydraw/view",
    icon: <CardGiftcardTwoTone />,
  },
  {
    header: "Settings",
  },
  {
    title: "Agents",
    to: "/agent/view",
    icon: <BadgeTwoTone />,
  },
  {
    title: "Form Type",
    to: "/form-type/view",
    icon: <DescriptionTwoTone />,
  },
  {
    title: "Plot Facing",
    to: "/plot-facing/view",
    icon: <AccountBalanceOutlined />,
  },
  {
    title: "Plot Size",
    to: "/plot-size/view",
    icon: <HomeWorkTwoTone />,
  },
  {
    title: "Payment Mode",
    to: "/payment-mode/view",
    icon: <AddCardTwoTone />,
  },
  {
    title: "Payment Plan",
    to: "/payment-plan/view",
    icon: <CurrencyRupeeTwoTone />,
  },
  {
    title: "Notification",
    to: "/notification/view",
    icon: <NotificationsTwoTone />,
  },
  {
    header: "App Settings",
  },
  {
    title: "Home Settings",
    to: "/home-settings",
    icon: <SettingsSuggestTwoTone />,
  },
  {
    title: "Mobile Payment Settings",
    to: "/mobile-payment/view",
    icon: <PhonelinkSetupTwoTone />,
  },
  {
    title: "Mobile Image Gallery Setting",
    to: "/mobile-image/view",
    icon: <ImageTwoTone />,
  },
  {
    title: "Mobile Video Gallery Setting",
    to: "/mobile-video/view",
    icon: <VideoSettingsTwoTone />,
  },
  {
    header: "About Panel",
  },
  {
    title: "About",
    to: "/about",
    icon: <InfoTwoTone />,
  },
];
